import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Segment, List, Container, Button } from 'semantic-ui-react'
import firebase from 'gatsby-plugin-firebase'
import moment from 'moment'

import '../CSS/articleList.css'

const ArticleList = ({ onArticleClicked, onBackBtnPressed }) => {
  const db = useRef(firebase.firestore())
  const [articles, setArticles] = useState([])

  useEffect(() => {
    db.current.collection('articles').get().then(snapshot => {
      setArticles(snapshot.docs.map(doc => ({
        ...doc.data(),
        key: doc.id,
      })))
    })
  }, [])

  const onNewArticleClicked = () => onBackBtnPressed({ newArticle: true })

  if (articles.length === 0) {
    return (
      <div style={{ color: 'whitesmoke', textAlign: 'center' }}>
        No articles were found.
      </div>
    )
  }

  return (
    <div>
      <Segment inverted className='list-segment'>
        <List divided inverted relaxed>
          {
            articles.map((article, index) => (
              <List.Item key={index} as='a' onClick={() => onArticleClicked(article)}>
                <List.Icon name='file alternate outline' size='big' verticalAlign='middle' />
                <List.Content>
                  <List.Header>{article.title}</List.Header>
                  Published on {moment(article.timestamp).format('LL [at] h:mm a')}
                </List.Content>
              </List.Item>
            ))
          }
        </List>
      </Segment>
      <Button inverted color='green' onClick={onNewArticleClicked}>New Article</Button>
    </div>
  )
}

ArticleList.propTypes = {
  onArticleClicked: PropTypes.func.isRequired,
  onBackBtnPressed: PropTypes.func.isRequired,
}

export default ArticleList
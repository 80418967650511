import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Form } from 'semantic-ui-react'
import firebase from 'gatsby-plugin-firebase'
import ImageUploader from 'react-images-upload'
import { Editor } from '@tinymce/tinymce-react'

import '../CSS/addEditArticles.css'

const AddEditArticle = ({ article, onBackBtnPressed }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [content, setContent] = useState(article?.content || '<p>Paste content here</p>')
  const [featureImage, setFeatureImage] = useState(null)
  const editorRef = useRef(null)
  const [title, setTitle] = useState(article?.title || '')
  const [summary, setSummary] = useState(article?.summary || '')
  const [slug, setSlug] = useState(article?.slug || '')

  const onDeleteClicked = async () => {
    if (!article) return

    if (confirm('Are you sure you want to delte this article? THIS ACTION CANNOT BE UNDONE!')) {
      const db = firebase.firestore()

      await db.collection('articles').doc(article.key).delete()

      alert('The article has successfully been deleted')
      onBackBtnPressed()
    }
  }

  const handleFormSubmit = async () => {
    if (
      title === '' ||
      summary === '' ||
      content === '' ||
      (featureImage === null && !article?.featureImageUrl) ||
      slug === ''
    ) {
      alert('Please make sure that you added a title, summary, feature image and content.')
      return
    }

    if (confirm('You are about to submit the article. Are you sure you want to proceed?')) {
      setIsLoading(true)

      // Upload feature image
      const featureImageUrl = featureImage ? await uploadFeatureImage() : article?.featureImageUrl

      // Upload content
      editorRef.current.uploadImages(async _success => {
        let res
        const db = firebase.firestore()

        try {
          if (article) {
            console.log({ article })
            res = await db.collection('articles').doc(article.key).set({
              title,
              summary,
              timestamp: moment().format(),
              featureImageUrl,
              slug,
              content
            }, { merge: true })
          } else {
            res = await db.collection('articles').add({
              title,
              summary,
              timestamp: moment().format(),
              featureImageUrl,
              slug,
              content
            })
          }

          setIsLoading(false)
          alert('Your document has been successfully saved!')
          onBackBtnPressed()
        } catch (err) {
          setIsLoading(false)
          alert('An error occured while saving the article to the db: ' + err)
        }
      })
    }
  }

  const handleEditorChange = (content, editor) => {
    console.log('Content updated', content)
    setContent(content)
  }

  const uploadFeatureImage = () => new Promise((success, failure) => {
    const storageRef = firebase.storage().ref()

    const newImageRef = storageRef.child(`images/${featureImage.lastModified}-${featureImage.name}`)
    newImageRef.put(featureImage).then(snapshot => {
      snapshot.ref.getDownloadURL().then(downloadUrl => {
        console.log('Uploaded at', downloadUrl)
        success(downloadUrl)
      })
    }).catch(error => {
      console.error('Error uploading image:', error)
      failure(error)
    })
  })

  const imageUploadHandler = (blobInfo, success, failure) => {
    // Must return the location of the file uploaded
    const storageRef = firebase.storage().ref()

    const newImageRef = storageRef.child(`images/${blobInfo.filename()}`)
    newImageRef.put(blobInfo.blob()).then(snapshot => {
      snapshot.ref.getDownloadURL().then(downloadUrl => {
        console.log(`Uploaded at ${downloadUrl}`)
        success(downloadUrl)
      })
    }).catch(error => {
      console.log('Error uploading image:', error)
      failure(error)
    })
  }

  const onImgDrop = picture => {
    if (picture.length === 0) {
      setFeatureImage(null)
    } else {
      setFeatureImage(picture[0])
    }
  }

  return (
    <div>
      <Button
        inverted
        color='orange'
        onClick={onBackBtnPressed}
      >
        Return to all articles
      </Button>
      <Form onSubmit={handleFormSubmit} loading={isLoading}>
        <Form.Field>
          <label className='white-text'>Title</label>
          <input
            placeholder='Article title'
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label className='white-text'>Summary Text</label>
          <input
            placeholder='Summary text'
            value={summary}
            onChange={e => setSummary(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label className='white-text'>URL</label>
          <div className='url-container'>
            <div className='white-text' style={{ paddingRight: 8 }}>
              https://filamind.io/articles/
              </div>
            <input
              value={slug}
              onChange={e => setSlug(e.target.value)}
              placeholder='Article URL. Must be unique.'
            />
          </div>
        </Form.Field>
        <Form.Field>
          <label className='white-text'>Feature image</label>
          <ImageUploader
            defaultImages={featureImage
              ? undefined
              : article?.featureImageUrl
                ? [article?.featureImageUrl]
                : undefined
            }
            withIcon={true}
            onChange={onImgDrop}
            imgExtension={['.jpg', '.png', '.gif', '.jpeg']}
            buttonText='Upload a feature image'
            singleImage
            withPreview
          />
        </Form.Field>
        <div className='editor'>
          <Editor
            apiKey='iqsnclamzgwkahm8kxy57h4g6e2bxyxdm4dwgdc11a1i2fdn'
            value={content}
            init={{
              setup: editor => editorRef.current = editor,
              images_upload_handler: imageUploadHandler,
              height: 500,
              menuBar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: `
              undo redo | formatselect | bold italic backcolor | 
              alignleft aligncenter alignright alignjustify | 
              bullist numlist outdent indent | removeformat | image | help
            `
            }}
            onEditorChange={handleEditorChange}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button inverted color='green' type='submit' className='submitBtn'>Submit</Button>
          <Button inverted color='red' className='submitBtn' onClick={e => {
            e.preventDefault()
            onDeleteClicked()
          }}>Delete article</Button>
        </div>
      </Form>
    </div>
  )
}

AddEditArticle.propTypes = {
  onBackBtnPressed: PropTypes.func.isRequired,
  article: PropTypes.object
}

export default AddEditArticle
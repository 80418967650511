import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ArticleList from './ArticleList'
import AddEditArticle from './AddEditArticle'

const LoggedInContentController = () => {
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [isNewArticle, setIsNewArticle] = useState(false)

  const onArticleClicked = article => setSelectedArticle(article)

  const onBackBtnPressed = (options) => {
    setSelectedArticle(null)
    setIsNewArticle(options?.newArticle || null)
  }

  if (selectedArticle || isNewArticle) {
    return (
      <AddEditArticle
        article={selectedArticle}
        onBackBtnPressed={onBackBtnPressed}
      />
    )
  }

  return (
    <ArticleList
      onArticleClicked={onArticleClicked}
      onBackBtnPressed={onBackBtnPressed}
    />
  )
}

LoggedInContentController.propTypes = {}

export default LoggedInContentController
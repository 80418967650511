import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DesktopLogo from '../images/desktopLogo.png'
import { Container, Grid, Button, Form } from 'semantic-ui-react'
import firebase from 'gatsby-plugin-firebase'
import Logo from '../images/desktopLogo.png'
import '../CSS/login.css'
import LoggedInContentController from '../components/LoggedInContentController'

const LoginPage = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const emailRef = useRef('')
  const pwRef = useRef('')

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(user !== null)
    })
  }, [])

  const handleFormSubmit = () => {
    firebase.auth().signInWithEmailAndPassword(emailRef.current.value, pwRef.current.value).catch(error => {
      setErrorMsg(error.message)
    })
  }

  if (isSignedIn) {
    // return <AddArticle />
    return (
      <PageContainer title='Articles'>
        <LoggedInContentController />
      </PageContainer>
    )
  }

  return (
    <Container className='login-container' >
      <Grid container centered style={{ height: '100%' }}>
        <Grid.Column verticalAlign='middle'>
          <Grid.Row>
            <a href='/'>
              <img src={DesktopLogo} alt='logo' />
            </a>
          </Grid.Row>
          <Grid.Row className='welcomeBack'>
            Welcome back!
          </Grid.Row>
          <Grid.Row className='form'>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label className='white-text'>Email</label>
                  <input
                    placeholder='Email'
                    ref={el => emailRef.current = el}
                  />
                </Form.Field>
                <Form.Field>
                  <label className='white-text'>Password</label>
                  <input
                    placeholder='Password'
                    ref={el => pwRef.current = el}
                    type='password'
                  />
                </Form.Field>
              </Form.Group>
              <div className='errorText'>
                {errorMsg}
              </div>
              <Button type='submit' className='submitBtn'>Submit</Button>
            </Form>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

LoginPage.propTypes = {}

const PageContainer = ({ children, title }) => {
  const onSignOutClicked = () => {
    firebase.auth().signOut()
  }

  return (
    <Container className='custom-container'>
      <div className='top-header'>
        <div className='logo'>
          <a href='/'>
            <img src={Logo} height={60} alt='filamind logo' />
          </a>
        </div>
        <div className='sign-out-btn'>
          <Button onClick={onSignOutClicked}>
            Sign Out
          </Button>
        </div>
      </div>
      <div className='page-title'>
        {title}
      </div>
      <div>
        {children}
      </div>
    </Container>
  )
}

PageContainer.propTypes = {
  title: PropTypes.string.isRequired,
}

export default LoginPage